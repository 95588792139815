import {MapWidget} from "./components/MapWidget";
import {FC} from "react";

const App: FC = () => {
  return (
      <MapWidget />
  )
}

export default App;
